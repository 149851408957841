/*
 * @Author: your name
 * @Date: 2022-04-07 09:57:32
 * @LastEditTime: 2022-04-21 11:10:50
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /Luckytu-Interface/src/store/index.ts
 */
import { createStore } from 'vuex'

export default createStore({
    state: {
        actProType:null
    },
    getters: {
    },
    mutations: {
        CHANGETYPE:(state,value) => {
            state.actProType = value
        }
    },
    actions: {
    },
    modules: {
    }
})