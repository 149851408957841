
import {
    defineComponent,
    reactive,
    ref,
} from 'vue'
import { useStore } from 'vuex'

export default defineComponent({
    name: 'TopBar',
    setup(){
        const store = useStore()
        
        const providerList = reactive([
            {label:'sid-2',value:2},
            {label:'sid-3',value:3},
            {label:'sid-4',value:4},
            {label:'sid-5',value:5},
            {label:'sid-6',value:6},
            {label:'sid-7',value:7},
            {label:'sid-8',value:8},
            {label:'sid-9',value:9},
            {label:'sid-10',value:10}
        ])
        //选择当前服务商类型
        const changeProType = (val:number) => {
            store.commit('CHANGETYPE',val)
        }
        const selectValue = ref(null)
        return {
            providerList,
            selectValue,
            changeProType
        }
    }
});
