/*
 * @Author: your name
 * @Date: 2022-04-07 09:57:32
 * @LastEditTime: 2022-04-19 18:22:12
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /Luckytu-Interface/src/main.ts
 */
import { createApp } from 'vue'
import {
    elements,
    elements_icon
} from './element-plus'
import 'element-plus/dist/index.css'
import '@/assets/style/global.less'
import '@/assets/style/commen.css'
import App from './App.vue'
import router from './router'
import store from './store'

const app = createApp(App)

elements.forEach(item => {
    app.use(item)
})

for(const name in elements_icon){
    app.component(name,elements_icon[name])
}

app.use(store).use(router).mount('#app')