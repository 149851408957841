/*
 * @Author: your name
 * @Date: 2022-04-07 09:57:32
 * @LastEditTime: 2022-04-22 17:27:27
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /Luckytu-Interface/src/router/index.ts
 */
import { createRouter, RouteRecordRaw,createWebHashHistory } from 'vue-router'
import HomeView from '../HomeView.vue'

const routes: Array<RouteRecordRaw> = [
    {
        path: '/',
        name: 'home',
        component: HomeView,
        redirect:'/index',
        children:[
            {path:'/index',name:'indexView',component:() => import('../views/indexView.vue')},
            {path: '/about',name: 'AboutView',component: () => import('../views/AboutView.vue')}
        ]
    },
    
]

const router = createRouter({
    history:createWebHashHistory(),
    routes
})

export default router
