/*
 * @Author: your name
 * @Date: 2022-04-07 13:47:10
 * @LastEditTime: 2022-04-22 17:54:59
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /Luckytu-Interface/src/element-plus.ts
 */
import { 
    ElButton,
    ElInput,
    ElAutocomplete,
    ElMenu,
    ElIcon,
    ElSelect,
    ElDescriptions,
    ElTable
} from 'element-plus'
import {
    Location, 
    Setting, 
    Menu ,
    Document
} from '@element-plus/icons-vue'

export const elements = [
    ElButton,
    ElInput,
    ElAutocomplete,
    ElMenu,
    ElIcon,
    ElSelect,
    ElDescriptions,
    ElTable
]
export const elements_icon = [
    Location, 
    Setting, 
    Menu ,
    Document
]